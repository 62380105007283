import React, { useEffect, useState } from "react"
import * as config from "../../../config.js"
import {
  CustomFormFieldsFragment,
  CustomFormsConfigurationFieldsFragment,
  DatoCmsDatoCmsFormUserEmailContentStructuredText,
  DatoCmsDatoCmsFormSubsidiaryEmailContentStructuredText,
} from "../../../graphql-types"
import * as styles from "./custom-form.module.scss"
import * as inputsStyles from "../layout/inputs.module.scss"
import { graphql } from "gatsby"
import Input, { ValidationType } from "../layout/form/input"
import Textarea from "../layout/form/textarea"
import RadioButton from "../layout/form/radio-button"
import Checkbox from "../layout/form/checkbox"
import Select from "../layout/form/select"
import RichDatoCmsContent from "../rich-datocms-content/rich-datocms-content"
import FileInput from "../layout/form/file-input"
import { useForm, FieldValues } from "react-hook-form"
import FormError from "../layout/form/form-error"
import { sendEmailV2, importFiles } from "../../api/notifications"
import { formToText } from "./form-to-text"
import { pushGtmEvent } from "../../gtm/interaction-event"
import { getFieldName } from "./field-name-checker"
import ReCAPTCHA from "react-google-recaptcha"

type RenderProps = {
  data: CustomFormFieldsFragment
  locale: string
  configuration: CustomFormsConfigurationFieldsFragment
  formCaptchaKey: string
}

const CustomForm: React.FC<RenderProps> = ({ data, locale, configuration, formCaptchaKey }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<FormData>()
  const formId = data.id.replace("DatoCmsForm-", "")
  const [formValues, setFormValues] = useState<Map<string, string | boolean | FileList>>(new Map())
  const [formState, setFormState] = useState<FormState>(FormState.Pending)
  const [firstClick, setFirstClick] = useState(true)
  const [captchaValue, setCaptchaValue] = useState("")

  useEffect(() => {
    const getTextWidth = (text: string, inputElement: HTMLElement) => {
      const span = document.createElement("span")
      span.textContent = text
      span.style.visibility = "hidden"
      span.style.font = window.getComputedStyle(inputElement).font
      document.body.appendChild(span)
      const width = span.offsetWidth
      document.body.removeChild(span)
      return width
    }

    const textFields = document.querySelectorAll<HTMLElement>(`.${styles.textField}`)
    textFields.forEach(textField => {
      const placeholderWidth = getTextWidth(textField.getAttribute("placeholder") || "", textField)
      const inputWidth = textField.offsetWidth

      if (placeholderWidth + 20 > inputWidth) {
        textField.style.maxWidth = placeholderWidth + 20 + "px"
      }
    })
  }, [])

  function onChange(fieldId, value) {
    const newValues = new Map(formValues)
    newValues.set(fieldId, value)
    setFormValues(newValues)
    if (errors[fieldId]) {
      clearErrors(fieldId)
    }
  }

  function pushGtmFirstClickEvent() {
    if (firstClick) {
      pushGtmEvent("Form", "Form", "Form 1st click", data.title)
      setFirstClick(false)
    }
  }

  const parseEmailContent = (content: string) => {
    let header = ""
    let footer = ""
    const search = "*Form content*"
    if (content.includes(search)) {
      const [before, after] = content.split(search)
      header = before.trim()
      footer = after.trim()
    } else {
      header = content.trim()
    }
    return { header, footer }
  }

  function onSubmit(formData: FieldValues) {
    const submitForm = () => {
      pushGtmEvent("eventga", "Form", "Form Submit", data.title)
      const userEmailContent = formToText(data.userEmailContent as DatoCmsDatoCmsFormUserEmailContentStructuredText)
      const subsidiaryEmailContent = formToText(
        data.subsidiaryEmailContent as DatoCmsDatoCmsFormSubsidiaryEmailContentStructuredText
      )

      const parsedUserEmailContent = parseEmailContent(userEmailContent)

      const parsedSubsidiaryEmailContent = parseEmailContent(subsidiaryEmailContent)

      const notEmptyEmailFieldIds = data.fieldsList
        .filter(field => field.__typename === "DatoCmsUserEmailField")
        .map(field => field.id)
        .filter(fieldId => !!formData[fieldId])
      const notEmptyFiles = data.fieldsList
        .filter(field => field.__typename === "DatoCmsFileField")
        .map(field => field.id)
        .filter(fieldId => !!formData[fieldId] && !!formData[fieldId][0])
        .map(fieldId => formData[fieldId][0])

      setFormState(FormState.Submitting)

      const filesPromises = notEmptyFiles.map(importFiles)

      Promise.allSettled(filesPromises).then(results => {
        results.forEach((result, index) => {
          if (result.status === "rejected") {
            const fieldId = data.fieldsList.find(
              field => field.__typename === "DatoCmsFileField" && formData[field.id]?.[0] === notEmptyFiles[index]
            )?.id

            if (fieldId) {
              setError(fieldId as keyof FormData, {
                type: "server",
                message: `Error importing file "${notEmptyFiles[index].name}"`,
              })
            }
          }
        })
      })

      const prmColor = config.primary_color
      let payload = {
        trigram: process.env.GATSBY_SUBSIDIARY_TRIGRAM,
        idForm: formId,
        fields: data.fieldsList

          .filter(field => !["DatoCmsSimpleText", "DatoCmsFileField"].includes(field.__typename))
          .map(field => {
            if ("name" in field) {
              if (field.__typename === "DatoCmsCheckboxField") {
                return { key: field.name, value: formData[field.id] ? "✓" : "✖︎" }
              }
              return { key: field.name, value: formData[field.id] }
            }
          }),

        logo: configuration.logoSubsidiary?.url || "",
        // prmColor = {red: 0, green: 0, blue: 0, alpha: 0}, we need to convert it to hex
        primaryColor: `#${prmColor.red.toString(16)}${prmColor.green.toString(16)}${prmColor.blue.toString(16)}`,

        locale: locale || "fr",
        files: notEmptyFiles,
      }

      const subsidiaryEmailPromise = sendEmailV2({
        ...payload,
        header: parsedSubsidiaryEmailContent.header,
        footer: parsedSubsidiaryEmailContent.footer,
      })

      const userEmailPromises = notEmptyEmailFieldIds.map(emailFieldId => {
        sendEmailV2({
          ...payload,
          email: formData[emailFieldId],
          header: parsedUserEmailContent.header,
          footer: parsedUserEmailContent.footer,
        })
      })

      return Promise.all([subsidiaryEmailPromise, ...userEmailPromises]).then(
        () => setFormState(FormState.Success),
        () => setFormState(FormState.Error)
      )
    }

    if (
      !formCaptchaKey ||
      (formCaptchaKey && captchaValue) ||
      (window.location.hostname === "localhost" && process.env.GATSBY_SUBSIDIARY_TRIGRAM.toUpperCase() === "KWP")
    ) {
      submitForm()
    } else {
      console.log("Captcha not valid", process.env.GATSBY_SUBSIDIARY_TRIGRAM)
    }
  }

  return (
    <form className={styles.customForm} onSubmit={handleSubmit(onSubmit)} noValidate={true}>
      <h2 className={styles.title}>{data.title}</h2>
      {data.fieldsList.map(field => {
        switch (field.__typename) {
          case "DatoCmsSingleLineTextField":
            const validationType = toValidationType(field.format)

            return (
              <div className={inputsStyles.formPart + " " + styles.formPart} key={field.id}>
                <label
                  htmlFor={field.id}
                  className={inputsStyles.label + (field.mandatory ? " " + inputsStyles.labelRequired : "")}
                >
                  {field.name}
                </label>
                <Input
                  id={field.id}
                  type="text"
                  name={field.id}
                  autocomplete={getFieldName(field.name) !== undefined}
                  placeholder={field.placeholder}
                  required={field.mandatory}
                  className={styles.textField}
                  aria-describedby="singleLineErrorDesc"
                  register={register}
                  validationType={validationType}
                  hasErrors={!!errors[field.id]}
                  onFocus={pushGtmFirstClickEvent}
                />
                <FormError errors={errors} name={field.id} type="required">
                  {configuration.requiredFieldError}
                </FormError>
                {validationType === ValidationType.PhoneNumber && (
                  <FormError errors={errors} name={field.id} type="pattern" id="singleLineErrorDesc">
                    {configuration.phoneNumberFieldError}
                  </FormError>
                )}
                {validationType === ValidationType.Email && (
                  <FormError errors={errors} name={field.id} type="pattern" id="singleLineErrorDesc">
                    {configuration.emailFieldError}
                  </FormError>
                )}
              </div>
            )

          case "DatoCmsUserEmailField":
            // field.id = getFieldName(field.name) != undefined ? getFieldName(field.name) : field.id
            return (
              <div className={inputsStyles.formPart + " " + styles.formPart} key={field.id}>
                <label
                  htmlFor={field.id}
                  className={inputsStyles.label + (field.mandatory ? " " + inputsStyles.labelRequired : "")}
                >
                  {field.name}
                </label>
                <Input
                  id={field.id}
                  type="text"
                  name={field.id}
                  autocomplete={getFieldName(field.name) !== undefined}
                  placeholder={field.placeholder}
                  required={field.mandatory}
                  className={styles.textField}
                  register={register}
                  validationType={ValidationType.Email}
                  hasErrors={!!errors[field.id]}
                  aria-describedby="emailErrorDesc"
                  onFocus={pushGtmFirstClickEvent}
                />
                <FormError errors={errors} name={field.id} type="required">
                  {configuration.requiredFieldError}
                </FormError>
                <FormError errors={errors} name={field.id} type="pattern" id="emailErrorDesc">
                  {configuration.emailFieldError}
                </FormError>
              </div>
            )

          case "DatoCmsMultiLinesTextField":
            return (
              <div className={inputsStyles.formPart + " " + styles.formPart} key={field.id}>
                <label
                  htmlFor={field.id}
                  className={inputsStyles.label + (field.mandatory ? " " + inputsStyles.labelRequired : "")}
                >
                  {field.name}
                </label>
                <Textarea
                  id={field.id}
                  name={field.id}
                  placeholder={field.placeholder}
                  required={field.mandatory}
                  hasErrors={!!errors[field.id]}
                  register={register}
                  onFocus={pushGtmFirstClickEvent}
                />
                <FormError errors={errors} name={field.id} type="required">
                  {configuration.requiredFieldError}
                </FormError>
              </div>
            )

          case "DatoCmsRadioButtonsField":
            return (
              <fieldset className={inputsStyles.formPart + " " + styles.formPart} key={field.id}>
                <legend className={inputsStyles.label + (field.mandatory ? " " + inputsStyles.labelRequired : "")}>
                  {field.name}
                </legend>
                <div className={inputsStyles.radioButtons}>
                  {(JSON.parse(field.options) as string[]).map((option, index) => (
                    <RadioButton
                      key={index}
                      id={field.id + index}
                      label={option}
                      name={field.id}
                      required={field.mandatory}
                      value={option}
                      hasErrors={!!errors[field.id]}
                      register={register}
                      onFocus={pushGtmFirstClickEvent}
                    />
                  ))}
                </div>
                <FormError errors={errors} name={field.id} type="required">
                  {configuration.requiredFieldError}
                </FormError>
              </fieldset>
            )

          case "DatoCmsCheckboxField":
            return (
              <div className={inputsStyles.formPart + " " + styles.formPart} key={field.id}>
                <Checkbox
                  key={field.id}
                  id={field.id}
                  label={field.name}
                  name={field.id}
                  required={field.mandatory}
                  hasErrors={!!errors[field.id]}
                  register={register}
                  onFocus={pushGtmFirstClickEvent}
                />
                <FormError errors={errors} name={field.id} type="required">
                  {configuration.requiredFieldError}
                </FormError>
              </div>
            )

          case "DatoCmsFileField":
            return (
              <div className={inputsStyles.formPart + " " + styles.formPart} key={field.id}>
                <label
                  htmlFor={field.id}
                  className={inputsStyles.label + (field.mandatory ? " " + inputsStyles.labelRequired : "")}
                >
                  {field.name}
                </label>
                <FileInput
                  key={field.id}
                  id={field.id}
                  type="file"
                  name={field.id}
                  placeholder={field.placeholder}
                  required={field.mandatory}
                  onChange={value => onChange(field.id, value)}
                  className={styles.fileField}
                  register={register}
                  onFocus={pushGtmFirstClickEvent}
                  accept=".jpg,.jpeg,.png,.gif,.doc,.docx,.pdf,.xls,.xlsx"
                />
                <FormError errors={errors} name={field.id} type="required">
                  {configuration.requiredFieldError}
                </FormError>
                <FormError errors={errors} name={field.id} type="server">
                  {errors[field.id]?.message}
                </FormError>
              </div>
            )

          case "DatoCmsSelectField":
            return (
              <div className={inputsStyles.formPart + " " + styles.formPart} key={field.id}>
                <label
                  htmlFor={field.id}
                  className={inputsStyles.label + (field.mandatory ? " " + inputsStyles.labelRequired : "")}
                >
                  {field.name}
                </label>
                <Select
                  key={field.id}
                  id={field.id}
                  name={field.id}
                  autocomplete={getFieldName(field.name) !== undefined}
                  options={JSON.parse(field.options)}
                  required={field.mandatory}
                  className={styles.select}
                  hasErrors={!!errors[field.id]}
                  register={register}
                  onFocus={pushGtmFirstClickEvent}
                />
                <FormError errors={errors} name={field.id} type="required">
                  {configuration.requiredFieldError}
                </FormError>
              </div>
            )

          case "DatoCmsSimpleText":
            return <RichDatoCmsContent key={field.id} data={[field]} />
        }
      })}

      {formCaptchaKey && <ReCAPTCHA sitekey={formCaptchaKey} onChange={onChangeCaptcha} />}

      <button
        type="submit"
        className={inputsStyles.primaryButton + " " + styles.submitButton}
        disabled={formState === FormState.Submitting || formState === FormState.Success}
      >
        {data.submitButtonLabel}
      </button>
      {formState === FormState.Error && (
        <div className={inputsStyles.formError + " " + styles.globalError}>{data.formError}</div>
      )}
      {formState === FormState.Success && <div className={styles.successMessage}>{data.successMessage}</div>}
    </form>
  )

  function onChangeCaptcha(value) {
    setCaptchaValue(value)
  }
}

export default CustomForm

export const fragments = graphql`
  fragment CustomFormFields on DatoCmsForm {
    id
    title
    submitButtonLabel
    from
    # receivers {
    #   ...EmailReceiversFields
    # }
    successMessage
    formError
    subsidiaryEmailSubject
    subsidiaryEmailContent {
      value
      blocks
      links {
        id: originalId
        fragmentType
      }
    }
    userEmailSubject
    userEmailContent {
      value
      blocks
      links {
        id: originalId
        fragmentType
      }
    }
    fieldsList {
      __typename
      ... on DatoCmsSingleLineTextField {
        id
        name
        placeholder
        mandatory
        format
      }
      ... on DatoCmsMultiLinesTextField {
        id
        name
        placeholder
        mandatory
      }
      ... on DatoCmsRadioButtonsField {
        id
        name
        mandatory
        options
      }
      ... on DatoCmsCheckboxField {
        id
        name
        mandatory
      }
      ... on DatoCmsFileField {
        id
        name
        mandatory
        placeholder
      }
      ... on DatoCmsSelectField {
        id
        name
        mandatory
        options
      }
      ... on DatoCmsUserEmailField {
        id
        name
        placeholder
        mandatory
      }
      ... on DatoCmsSimpleText {
        ...SimpleTextFields
      }
    }
  }

  fragment EmailReceiversFields on DatoCmsEmailReceiversBlock {
    email
    sendType
  }

  fragment CustomFormsConfigurationFields on DatoCmsFormsConfiguration {
    requiredFieldError
    phoneNumberFieldError
    emailFieldError
    logoSubsidiary {
      alt
      url
    }
  }
`

function toValidationType(format: string) {
  switch (format) {
    case "Email":
      return ValidationType.Email
    case "Phone number":
      return ValidationType.PhoneNumber
    default:
      return ValidationType.None
  }
}

enum FormState {
  Pending,
  Submitting,
  Success,
  Error,
}
